.game {
    display: flex;
    flex-direction: column;
    // background: green;

    &-header {
        // background: red;
        width: 24rem;
        height: 2.8rem;
        text-align: center;
        position: relative;
        z-index: 30;
    }
}

.timer {
    width: 100%;
    height: 100%;
}

.blobs {
    position: relative;
    width: 100%;
    height: 26rem;
    top: 50%;
    transform: translateY(-50%);
    // background: red;

    .tk-blob {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &-seconds {
        font-size: 5rem;
        font-weight: 900;

    }
}

.buttons {
    display: flex;
    justify-content: center;
    width: max-content;
    margin: auto;
    gap: 20px;
}