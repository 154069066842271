// Import all montserrat font from google fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.App {
    height: 100vh;
    display: grid;
    place-items: center;
    background: rgb(236, 236, 236);

    .wrapper {
        display: flex;
        gap: 7rem;
    }
}

.panel {
    width: 20vw;
    height: 60vh;
    background: white;
    padding: 20px;
    border-radius: 10px;
    

    &-header {
        margin-bottom: 10px;
    }
}

.input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}